@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html {
    @apply h-full bg-gray-50;
  }

  body {
    @apply h-full bg-white;
  }

  h1 {
    @apply text-4xl font-semibold;
  }

  h2 {
    @apply text-xl font-semibold mb-2;
  }

  h3 {
    @apply text-xl font-semibold mb-2;
  }

  a {
    @apply text-accent hover:underline;
  }

  section {
    @apply mb-6;
  }

  .mdx-content p {
    @apply text-xl font-light;
  }

  .mdx-content h2 {
    @apply mt-6;
  }

  .mdx-content h3 {
    @apply mt-6;
  }
  .mdx-content ul {
    @apply list-inside list-disc;
  }
}
